import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {useForm} from "antd/es/form/Form.js";
import {Alert, Button, Card, Col, Divider, Form, Input, Row, Select, Space, Spin, Switch, Tabs} from "antd";
import _ from "lodash";
import {Showable, Switch2} from "./Switcher.js";
import {callWithCatch, pp} from "./utils.js";
import MyWS from "./MyWS.js";
import {Bot} from "lucide-react";
import HtmlDiv from "./HtmlDiv.js";
import {useEffectOnce} from "react-use";
import User from "./User.js";
import Vimeo from '@u-wave/react-vimeo';
import Spacer from "./Spacer.js";
import {FileWordTwoTone} from "@ant-design/icons";

let inAdmin = location.pathname.includes("/admin");

let byTypes = {
    "כתב תביעה": ["התובע", "התובעת", "התובעות", "התובעים",],
    "כתב הגנה": ["הנתבע", "הנתבעת", "הנתבעות", "הנתבעים",],
    "בקשה": ["המבקש", "המבקשת", "המבקשות", "המבקשים",],
    "תשובה": ["המשיב", "המשיבה", "המשיבות", "המשיבים",],
    "עתירה": ["העותר", "העותרת", "העותרות", "העותרים",],
    // "כתב ערעור": ["המערער", "המערערת", "המערערות", "המערערים",],
    "ערעור": ["התובע", "התובעת", "התובעות", "התובעים", "הנתבע", "הנתבעת", "הנתבעות", "הנתבעים","העותר", "העותרת", "העותרות", "העותרים", "הנאשם", "הנאשמת", "הנאשמות", "הנאשמים" ],
    "ערר": ["העורר", "העוררת", "העוררות", "העוררים",],
}

let helpLines = {
    "כתב תביעה": "תביעה להחזר היטל השבחה ופיצוי מוסכם בהסכם מכר",
    "כתב הגנה": "כתב הגנה מטעם חברת בניה בתביעת נזיקין בטענת רשלנות בפיקוח על עובד",
    "בקשה": "בקשה לחידוש הליכי פירוק בגין הפרת הסכם פשרה",
    "תשובה": "תגובה לבקשה להגשת ראיות חסויות במעמד צד אחד",
    "עתירה": "עתירה נגד החלטת משרד הפנים להפסקת הליך הסדרת מעמד בישראל",
    "ערעור": "ערעור על דחיית תביעה בגין ליקויי בניה",
    "ערר": ["העורר", "העוררת", "העוררות", "העוררים",],
}

let getByTypes = (type) => {
    return byTypes[type] || ["המשיב", "המשיבה", "המשיבות", "המשיבים",]
}

const Faq = () => {
    let examples = [
        ["מה בדיוק עושה המערכת החדשה?", "כותבת לך כתב טענות מלא, יסודי (של כ-10 עמודים) לפי התקנות ומותאם אישית לכל מקרה."],
        ["איך זה עובד?", <div>
            <div><u>כתב תביעה</u> / <u>בקשה</u> / <u>עתירה</u>: מזינים את הרקע הגולמי של המקרה במילים שלכם</div>
            <div><u>כתב הגנה</u>: מעלים את כתב התביעה בקובץ PDF</div>
            <div><u>כתב תשובה</u>: מעלים קובץ PDF של המסמך עליו מוגשת התשובה: (בקשה, עתירה, ערעור וכו')</div>
            <div><u>ערעור</u>: מעתיקים את פסק הדין עליו מוגש הערעור</div>
        </div>],
        ["כיצד המערכת יודעת להכין לי כתב טענות?", "המערכת מנתחת את הפרטים הספציפיים של כל מקרה, כגון העובדות, החוקים הרלוונטיים והפסיקה ומזהה את הטיעונים המשפטיים החזקים ביותר."],
        ["מה היתרון העיקרי?", "חיסכון בזמן, שיפור איכות הכתיבה, קבלת תובנות משפטיות יקרות ערך שיעזרו לך לפתח אסטרטגיה משפטית מוצלחת"],
    ]

    return <Card title='שאלות נפוצות על יצירת כתבי הטענות:'>
        {examples.map(r =>
            <div style={{marginBottom: "1rem"}} key={r[0]}>
                <div><b>{r[0]}</b></div>
                <div>{r[1]}</div>
                <div>{r[2]}</div>
            </div>)}
    </Card>
}

const Pricing = () => <div className="space-y-4">
    <Price mid="2a9198096f9e045e05f08566217a3acc" price={49} title="כתב טענות אחד ומעלה"/>
    <Price mid="1d75a7415aa37b0e234784aec389e9f5" price={39} title="20 כתבי טענות (20% הנחה)"/>
</div>

const Price = observer(({mid, price, title}) => {
    let clickBuy = () => {
        User.track('briefgen.buy')
        window.open("https://www.meshulam.co.il/purchase?b=" + mid, '_blank')
    }

    return <div>
        <Card size="small" title={title}>
            <div>
                <Space>
                    <h2 className="text-[24px] font-bold text-dark">
                        <span className="ltr">{price}</span>
                    </h2>
                    <span className="text-base font-medium text-body-color">
                           ₪ + מע״מ
                        </span>
                </Space>
                <div>לכל כתב טענות</div>
                <br/>
            </div>
            <Button onClick={clickBuy} type="primary">רכישה</Button>
        </Card>
    </div>
});

const BriefGenDL = ({bg}) => <Button icon={<FileWordTwoTone/>} href={"/brief_gens/" + bg.id}
                                     variant="text">הורדה</Button>;

const BgView = observer(({bg}) => {
    if (!bg)
        return null;

    let extra = <Space>
        {User.isAdmin() && bg.file_link && <a href={bg.file_link} target="_blank">הקובץ שהועלה</a>}
        <BriefGenDL bg={bg}/>
    </Space>

    return <Card title={bg.name} extra={extra} size="small">
        <Tabs>
            <Tabs.TabPane tab="כתב הטענות" key="1">
                <div style={{fontSize: "14px", whiteSpace: "pre-line", width: "600px"}} className="">
                    <HtmlDiv html={bg.result}/>
                </div>
            </Tabs.TabPane>
            {bg.tabs.map(([t, v]) => <Tabs.TabPane tab={t} key={t}>
                <div style={{fontSize: "14px", whiteSpace: "pre-line", width: "600px"}} className="">
                    {v}
                </div>
            </Tabs.TabPane>)}
            {bg.admin_tabs.map(([t, v]) => <Tabs.TabPane tab={t} key={t}>
                <div style={{fontSize: "14px", whiteSpace: "pre-line", width: "600px"}} className="">
                    {v}
                </div>
            </Tabs.TabPane>)}
        </Tabs>
    </Card>
});

const AiStream = observer(({
                               params, loading, setLoading
                           }) => {
    const [result, setResult] = useState(false);

    let gen = async () => {
        if (!params)
            return;

        setLoading(true);
        setResult(false);
        callWithCatch({
            url: `/searches/ai`,
            method: 'post',
            params: {brief_gen: true, ...params},
            onErr: () => {
                setLoading(false)
            },
            onSuccess: data => {
                let sub;
                if (data.text) {
                    setLoading(false);
                    setResult(data)
                }

                if (data.sub_to)
                    sub = MyWS(data.sub_to, r => {
                        setResult(r)
                        if (r.done || r.err) {
                            setLoading(false);
                            sub.unsubscribe();
                        }
                    })
            }
        })
    }

    useEffect(() => {
        gen()
    }, [params])

    if (!params)
        return null;

    let title = <Space><Bot/><Spin/></Space>;
    if (result?.done)
        title = <Space><Bot/>
            <div>סיימתי!</div>
        </Space>;

    if (result?.err) {
        pp(result?.err)
        return <Alert type="error" description="אופס! קרתה תקלה ביצרית כתב הטענות. אבל אל דאגה! באפשרותך לפנות אלינו לסיוע ישירות בווטסאפ או באתר" style={{width: "450px"}}/>
    }

    let spinText = s => {
        if (!s)
            return null;

        if (s.includes("@@"))
            return <span><span><HtmlDiv html={s.replace(/@@/, "")}/></span><span><Spin/></span></span>

        return <HtmlDiv html={s}/>
    }

    let dl = result?.done && <BriefGenDL bg={result}/>

    return <div className="w-full sm:flex-grow m-4">
        <Card size="small" title={title} extra={dl}>
            {result &&
                <div style={{fontSize: "14px", whiteSpace: "pre-line", width: "500px"}} className="">
                    {spinText(result.text)}
                    <br/>
                    {result.done &&
                        <div style={{fontSize: "10px", color: "#9CA3AF"}}>הAI יכול לעשות טעויות. אנא ודאו את
                            תשובותיו עם הפסיקה.</div>}
                </div>}
        </Card>
        <br/>
        <br/>
    </div>

})


export default observer(() => {
    const [bg, setBg] = useState(null);
    const [bgs, setBgs] = useState(null);
    const [gen, setGen] = useState(false);

    useEffectOnce(() => {
        callWithCatch({
            url: "/brief_gens",
            method: 'get',
            params: {admin: inAdmin},
            onSuccess: data => {
                setBgs(data.brief_gens)
            },
        })
    })

    if (gen)
        return <Gen cancel={() => setGen(false)}/>;

    let credits = gon.user.credits;
    let team_credits = gon.user.team_credits;

    let onPlay = () => {
        User.track('briefgen.play')
    }

    let bgCard = bg => {
        return <div key={bg.id}>
            <div className="text-gray-500" style={{fontSize: "12px"}}>
                <Space split={<Divider type="vertical"/>} gap={1}>
                    <div>{bg.created_at}</div>
                    {inAdmin && <div>{bg.user}</div>}
                </Space>
            </div>
            <div>
                <a onClick={() => setBg(bg)}>{bg.name}</a>
            </div>
        </div>
    }

    if (inAdmin)
        return <div className="flex flex-wrap">
            <div className="w-1/4">
                <div className="space-y-4">
                    {bgs && bgs.map(bg => bgCard(bg))}
                </div>
            </div>
            <div className="w-3/4">
                <BgView bg={bg}/>
            </div>
        </div>


    return <div className="flex flex-wrap">
        <div className="w-1/4">
            <div className="space-y-4">
                <Showable v={team_credits}>
                    <div className="ml-8 space-y-4">
                        <div>יש למשרד {team_credits} כתבי טענות ליצור</div>
                        <Button type="primary" onClick={() => setGen(true)}>יצירת כתב טענות חדש</Button>
                    </div>
                </Showable>
                <Showable v={credits}>
                    <div className="ml-8 space-y-4">
                        <div>יש לך {credits} כתבי טענות ליצור</div>
                        <Button type="primary" onClick={() => setGen(true)}>יצירת כתב טענות חדש</Button>
                    </div>
                </Showable>
                <Showable v={!(credits || team_credits)}>
                    <div className="ml-8">
                        <Pricing/>
                    </div>
                </Showable>

                {bgs && bgs.map(bg => bgCard(bg))}
            </div>
        </div>
        <div className="w-3/4">
            {!bg && <div>
                <Faq/>
                <br/>
                <Card title="איך זה עובד:">
                    <Vimeo
                        // width="750px"
                        onPlay={onPlay}
                        responsive={true}
                        video="1012504957"
                    />
                </Card>
            </div>}
            <BgView bg={bg}/>
        </div>
    </div>
});

let Gen = observer(({cancel}) => {
    let [form] = useForm();
    const type = Form.useWatch('type', form);
    const original_type = Form.useWatch('original_type', form);
    const [showOpinion, setShowOpinion] = useState(false);
    const [gen, setGen] = useState(false);
    const [loading, setLoading] = useState(false);

    let types = _.keys(byTypes);
    let supportedTypes = ["כתב תביעה", "כתב הגנה", "בקשה", "עתירה", "ערעור"];
    let responseTypes = ["בקשה", "עתירה", "ערעור", "כתב הגנה", "תשובה"];

    // if (User.beta()) {
        supportedTypes.push("תשובה");
    // }

    if (gen)
        return <AiStream params={gen} loading={loading} setLoading={setLoading}/>

    let hagana = type === "כתב הגנה";
    let tvia = type === "כתב תביעה";
    let irur = type === "ערעור";
    let tshuva = type === "תשובה";

    let og_type_label = "כתב הטענות שקיבלתם";

    let startGen = params => {
        if (hagana || tshuva)
            params.file = document.querySelector('#file').files[0];

        setGen(params);
    }

    let asciiSet = (f, e) => form.setFieldsValue({[f]: _.unescape(e.target.value)});

    let povLabel;
    if (tshuva)
        povLabel = "התשובה מוגשת ע\"י:";
    else if (irur)
        povLabel = "בערכאה הקודמת, מגיש הערעור הנוכחי היה:";
    else
        povLabel = "מוגש ע״י";
    
    let subjectLabel;
    if (tshuva)
        subjectLabel = "נושא " + og_type_label + ":";
    else
        subjectLabel = "כותרת";

    return <div>
        <Form form={form}
              onFinish={startGen}
              layout="vertical"
        >
            <Form.Item name="type" label="סוג כתב טענות" rules={[{required: true}]}>
                <Select options={types.map(t => {
                    if (supportedTypes.includes(t))
                        return {label: t, value: t};
                    else {
                        let label = <div className="flex space-between">
                            <div className="flex-1">{t}</div>
                            <div className="flex-1 text-left">בקרוב!</div>
                        </div>
                        return {label, value: t, disabled: true}
                    }
                })}
                        onChange={() => {
                            form.setFieldsValue({by: null});
                        }}
                        style={{width: "175px"}}
                />
            </Form.Item>
            <Showable v={type}>
                <Showable v={tshuva}>
                    <Form.Item name="original_type" label='תשובה ל:' rules={[{required: true}]}>
                        <Select options={responseTypes.map(t => ({value: t, label: t === "תשובה" ? "תגובה (תשובה לתגובה)" : t}))}
                                style={{width: "175px"}}
                        />
                    </Form.Item>
                    <Form.Item name="file" label={og_type_label} rules={[{required: true}]}
                               extra="העלו את כתב הטענות שקיבלתם (PDF)">
                        <Input type="file" accept=".pdf" style={{width: "450px"}} id="file"/>
                    </Form.Item>
                    <Form.Item name="original_pov" label='הוגש ע"י:' rules={[{required: true}]}>
                        <Select options={getByTypes(original_type).map(t => ({label: t, value: t}))}
                                style={{width: "175px"}}
                        />
                    </Form.Item>
                </Showable>
                <Form.Item name="pov" label={povLabel} rules={[{required: true}]}>
                    <Select options={getByTypes(type).map(t => ({label: t, value: t}))}
                            style={{width: "175px"}}
                    />
                </Form.Item>
                <Form.Item name="subject" label={subjectLabel} rules={[{required: true}]} extra={"לדוגמא: " + helpLines[type]}>
                    <Input style={{width: "450px"}}/>
                </Form.Item>
                <Showable v={hagana}>
                    <Form.Item name="file" label="כתב התביעה" rules={[{required: true}]}
                               extra="העלו את כתב התביעה שקיבלתם (PDF)">
                        <Input type="file" accept=".pdf" style={{width: "450px"}} id="file"/>
                    </Form.Item>
                </Showable>
                <Showable v={tvia}>
                    <Space>
                        <div>הוספת חוות דעת רפואית</div>
                        <Switch onClick={() => setShowOpinion(!showOpinion)}/>
                    </Space>
                    <br/>
                    <br/>
                    {showOpinion && <Form.Item name="expert" label="חוות דעת רפואית">
                        <Input.TextArea rows={10} style={{width: "450px"}} onChange={e => asciiSet("expert", e)}/>
                    </Form.Item>}
                </Showable>
                <Showable v={irur}>
                    <Form.Item name="og_text" label="נא להעתיק לתיבה זו את פסק הדין עליו מוגש הערעור:"
                               onChange={e => asciiSet("og_text", e)}
                               rules={[{required: true}]}>
                        <Input.TextArea rows={10} style={{width: "450px"}}/>
                    </Form.Item>
                    <Form.Item name="background"
                               label="ניתן להוסיף נקודות שלדעתך יכולות לחזק את סיכויי הערעור (לא חובה)" rules={[]}
                               onChange={e => asciiSet("background", e)}
                               extra="">
                        <Input.TextArea rows={10} style={{width: "450px"}}/>
                    </Form.Item>
                </Showable>
                <Showable v={!irur}>
                    <Form.Item name="background" label="רקע" rules={[{required: !tshuva}]}
                               extra="כאן חשוב לכתוב בעיקר נתונים שהמערכת לא יכולה לדעת כגון: רקע עובדתי, השתלשלות העניינים, וכל פרט רלוונטי אחר.">
                        <Input.TextArea rows={10} style={{width: "450px"}} onChange={e => asciiSet("background", e)}/>
                    </Form.Item>
                </Showable>
            </Showable>
            <Showable v={type}>
                <Alert type="warning" description="אחרי יצירת כתב הטענות לא ניתן לשנות את הרקע שהזנתם"
                       style={{width: "450px"}}/>
                <br/>
                <Space>
                    <Button type="primary" loading={loading} htmlType="submit">צרו את כתב הטענות</Button>
                    <Button onClick={cancel}>ביטול</Button>
                </Space>
            </Showable>

        </Form>
    </div>
})

